import React from 'react';
import {Container, Row } from 'react-bootstrap';

import Item from './Item';

const Popular = ({popular=[]}) => {
  return (
    <section className="popular-area mt-5">
      <Container>
        <h2 className="section-title">Most Popular</h2>
        <Row className="popular-content mt-4">
          {popular.map(item => <Item item={item} key={item._id+Math.random()}/> )}
        </Row>
      </Container>
    </section>
  );
};

export default Popular;