import Head from "next/head";
import Banner from "../components/banner/Banner";
import Features from "../components/Features/Features";
import Header from "../components/Header/Header";
import Popular from "../components/popular/Popular";
import Posts from "../components/posts/Posts";
import Topics from "../components/topics/Topics";
import axios from "axios";
import { Col, Container,Spinner } from "react-bootstrap";
import { GiNetworkBars } from "react-icons/gi";
import { GoMegaphone } from "react-icons/go";
import { MdLibraryBooks } from "react-icons/md";
import { RiCodeBoxFill, RiUserVoiceFill } from "react-icons/ri";
import { FaFileCode } from "react-icons/fa";
import { useEffect, useState } from "react";

export default function Home({ blogs, popular, feature, category = [] }) {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("all");
  const [filterData, setFilterData] = useState([]);
  const [allBlogs, setAllBlogs] = useState([...blogs]);
  const [topics, setTopics] = useState([...category]);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const lists = category.map((item, i) => {
          const newItem = { ...item };
          newItem.icon = <FaFileCode />;
          return newItem;
        });
        setTopics([
          { title: "All", slug: "all", _id: "all", icon: <MdLibraryBooks /> },
          ...lists,
        ]);
      } catch (error) {
        console.log(error);
      }
    };
    if (category.length > 0) {
      fetchTopics();
    }
  }, [category]);

  const fetchMoreBlogByCategories = async (id) => {
    setLoading(true);
    try {
      const currentPage = page + 1;
      const { data } = await axios.get(
        `https://app.programming-hero.com/api/blog/categories/${id}?page=${currentPage}&limit=10`
      );
      setPage((prev) => currentPage);
      if (data.data.page === currentPage || data.data.page < currentPage ) {
        setPage(null);
      }
      if (data?.data?.blogs?.length > 0) {
        setFilterData([...filterData,...data?.data?.blogs]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchBlogByCategories = async (id) => {
    try {
      const { data } = await axios.get(
        `https://app.programming-hero.com/api/blog/categories/${id}?page=1&limit=10`
      );
     
      if (data?.data?.blogs?.length > 0) {
        setFilterData([...data?.data?.blogs]);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedItem === "all") {
      setFilterData([...allBlogs]);
    } else {
      fetchBlogByCategories(selectedItem);
    }
  }, [allBlogs, selectedItem]);

  const changeHandler = (item) => {
    setPage(1)
    setSelectedItem(item);
  };
  return (
    <>
      <Head>
        <meta property="og:title" content="Programming Hero" />
        <meta property="og:image" content="thumbnail.png" />
        <meta
          name="description"
          property="Programming Hero - Blog"
          content="Enjoy a personalized, fun, and interactive learning process while becoming a Programming Hero."
        />
        <meta
          property="og:url"
          content="https://www.programming-hero.com/blog/"
        />
        <meta property="og:image" content="thumbnail.png" />
        <meta name="twitter:card" content="programming Hero - Blog" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Programming Hero</title>
      </Head>
      <section className="header-container">
        <Header />
        <Banner />
      </section>
      <Topics
        changeHandler={changeHandler}
        selectedItem={selectedItem}
        category={topics}
      />
      {selectedItem === "all" ? (
        <>
          <Features popular={feature} />
          <Popular popular={popular} />
          <Posts loadMore title="Latest posts" blogs={blogs} />
        </>
      ) : (
        <>
          <Posts title="" blogs={filterData} />
          <Container>
            <Col>
              <button
                type="button"
                className="btn primary loading-btn text-center text-white m-auto d-block"
                onClick={() => fetchMoreBlogByCategories(selectedItem)}
                disabled={!page}
              >
                {" "}
                {loading ? <Spinner animation="border" />: (
          page ? `Load more`:`No more post!`
        )}
              </button>
            </Col>
          </Container>
        </>
      )}
    </>
  );
}

export async function getStaticProps() {
  const [res1, res2, res3, res4] = await Promise.all([
    axios.get(`https://app.programming-hero.com/api/blog?page=1&limit=6`),
    axios.get(`https://app.programming-hero.com/api/blog/feature`),
    axios.get(`https://app.programming-hero.com/api/blog/mostpopular`),
    axios.get(`https://app.programming-hero.com/api/blogCategory`),
  ]);
  const { blogs } = res1.data.data;
  const { data: feature } = res2.data;
  const { data: popular } = res3.data;
  const { data: category } = res4.data;

  return {
    props: {
      blogs,
      popular,
      feature,
      category,
    },
    revalidate: 10,
  };
}
