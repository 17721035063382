import { Card, Col,ListGroupItem } from 'react-bootstrap';
import Link  from 'next/link';
import Image from 'next/image'
import { getDate } from '../../lib/utilities';

const Item = ({item}) => {
    return (
        <Col md={6} className="mb-4" key={item._id}>
        <div className="popular-item">
          <Card className="shadow d-flex justify-content-between align-items-center">
            <Card.Body>
                <Link href={`/blogs/${item.slug}`}>
                    <a><Card.Title className="mb-0">{item.title.slice(0,35)}</Card.Title></a>
                </Link>
              <div className="post-meta d-flex justify-content-between pt-1">
                <ListGroupItem className="ml-0 pl-0">by
                <span>&nbsp;{item?.authorId?.fullName || "Admin"}</span>
                </ListGroupItem>
                <ListGroupItem>{getDate(item.created_at)}</ListGroupItem>
              </div>
            </Card.Body>
            <div className="right">
              <Image width="155" alt="programming hero" height={80} className="image w-100" src={item.thumbnail} />
            </div>
          </Card>
        </div>
      </Col>
    );
};

export default Item;