import { Container, ListGroup, Row } from 'react-bootstrap';
import { FaFileCode } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import styles from '../../styles/sliderArrow.module.css';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={`${className} ${styles.nextArrow}`} style={{ ...style }} onClick={onClick}>
            <BsArrowLeftShort color="#5A5AFF" size={20} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={`${className} ${styles.prevArrow}`} style={{ ...style }} onClick={onClick}>
            <BsArrowRightShort color="#5A5AFF" size={20} />
        </div>
    );
}

const Topics = ({category=[],changeHandler,selectedItem}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <section className="tag-area mt-3">
      <Container>
        <Row>
          <ListGroup variant="flush" className="tags w-100">
            <Slider {...settings} className="tags w-100">
              {category.map(item => (
                <ListGroup.Item key={item._id} onClick={()=>changeHandler(item._id)} className={`${item._id === selectedItem ? 'active topis-item' : 'topis-item' }`}> <span>{item.icon}</span> {item.title}</ListGroup.Item>
              ) )}
            </Slider>
          </ListGroup>
        </Row>
      </Container>
    </section>
  );
};

export default Topics;