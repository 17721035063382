import { Col, Container, Row } from 'react-bootstrap';
import styles from '../../styles/banner.module.css';
import Image from 'next/image'

const Banner = () => {
  return (
    <section className={styles.topBanner}>
      <Container >
        <Row>
          <Col md={6}>
            <div className="banner-content text-white">
              <h1>HERO BLOG</h1>
              <p>Enjoy personalized, fun, and interactive learning process
while becoming a Programming Hero.</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="banner-img text-center">
              <Image width={240} height={220} src="/top-banner.png" alt="programming hero" className="img" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;