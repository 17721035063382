import { useEffect, useState } from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';
import PostItem from './post/PostItem';
import axios from 'axios';
const Posts = ({blogs=[],loadMore,title}) => {

  const [posts,setPosts] = useState([...blogs])
  const [page,setPage] = useState(1)
  const [loading,setLoading] = useState(false)
  const fetchMorePost = async () => {
    setLoading(true)
    try {
      const currentPage = page + 1;
      const {data} = await axios.get(`https://app.programming-hero.com/api/blog?page=${currentPage}&limit=6`);
      setPage(prev => currentPage)
  
      if(data.data.page === currentPage) {
        setPage(null)
      }
      const filterPosts = data.data.blogs;
      setPosts([...posts, ...filterPosts])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(()=>{
    if(blogs.length >0 ) {
      setPosts([...blogs])
    } else {
      setPosts([])
    }
  
  },[blogs])
  return (
    <section className="posts-area mt-5">
      <Container>
        <h2 className="section-title">{title}</h2>
        <hr />
        <Row className="mb-4">
         {posts.length > 0 ? (
          posts.map((item,i )=>  <PostItem key={i+item._id} item={item} />)
         ):(
           <h3>No item Found!</h3>
         )}
        </Row>
      { loadMore && <button type="button"  className="btn primary text-center text-white loading-btn m-auto d-block" onClick={fetchMorePost} disabled={!page}> {loading ? <Spinner animation="border" />: (
          page ? `Load more`:`No more post!`
        )}</button>}
      </Container>
    </section>
  );
};

export default Posts;
