import React from 'react';
import { Card, Col, ListGroupItem } from 'react-bootstrap';
import { BsArrowRightShort } from 'react-icons/bs';
import { HiArrowNarrowRight } from 'react-icons/hi';
import  Link  from 'next/link';
import Image from 'next/image'
import { getDate, removeTags, shorten } from '../../../lib/utilities';


const PostItem = ({ item ={}}) => {
  let author = ''
  if(item.authorId) {
    author = <span>&nbsp;{item?.authorId?.fullName}</span>
  } else if(item.author){
    author = <span>&nbsp;{item?.author[0]?.fullName}</span>
  }
  return (
    <Col md={4} className="mb-4">
      <div className="post-content">
        <Card className="shadow">
          <div className="card-top">
            <Image height={170} width={360} alt="programming hero" className="w-100" variant="top" src={item.thumbnail} />
            <div className="bottom-area">
              <Link href={`/blogs/${item.slug}`}>
                  <a><button>Read More <span><HiArrowNarrowRight size={16} /></span></button></a>
            </Link>
            </div>
          </div>
          <Card.Body>
          <Link href={`/blogs/${item.slug}`}>
            <a>
            <Card.Title>{shorten(item.title,40)}</Card.Title>
            </a>
            </Link>
            <div className="post-meta d-flex justify-content-between pb-1 pt-1">
              <ListGroupItem className="ml-0 pl-0">by {author}</ListGroupItem>
              <ListGroupItem>{getDate(item.created_at)}</ListGroupItem>
            </div>
            <p
            className="card-text">{removeTags(item.body,95)} ...</p>
            <div className="read-more">
              <Link href={`/blogs/${item.slug}`}><a>Read More <BsArrowRightShort size={20} /></a></Link>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
};

export default PostItem;  