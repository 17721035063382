import { Card, Col, Container, ListGroupItem, Row } from 'react-bootstrap';
import { HiArrowNarrowRight } from 'react-icons/hi';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Link from 'next/link';
import { getDate, shorten } from '../../lib/utilities';
import { useRouter } from 'next/router'

const Features = ({popular}) => {
  const router = useRouter()
  const handleClick = path => {
    router.push(path)
  }

  var settings = {
    dots: true,
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section className="features-area">
      <Container>
        <h2 className="section-title">Featured article</h2>
        <hr />
        <Row style={{ display: 'block' }}>

          <Slider {...settings}>

            {popular.map(item => (
                <Col key={item._id} className="">
                  <div className="features-content">
                    <Card className="shadow mb-3">
                      <div className="hover-area">
                        <Card.Img variant="top" alt="Programming Hero" src={item.thumbnail} />
                        <div className="bottom-area">
                          <button onClick={() =>handleClick(`/blogs/${item.slug}`)}>
                            Read More <span><HiArrowNarrowRight size={16} />
                            </span> </button>
                        </div>
                      </div>
                      <Card.Body>
                        <Link href={`/blogs/${item.slug}`}>
                            <a><Card.Title>{shorten(item.title,50)}</Card.Title></a>
                        </Link>
                        <div className="post-meta d-flex">
                          <ListGroupItem className="ml-0 pl-0">by 
                          <span>&nbsp;{item?.authorId?.fullName}</span></ListGroupItem>
                          <ListGroupItem>{getDate(item.created_at)}</ListGroupItem>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
            ))}
          </Slider>
        </Row>
      </Container>
    </section>
  );
};

export default Features;